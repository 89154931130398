import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1440p CGI Commands",
  "path": "/1440p_Series_CGI_List/Network_Menu/IP_Configuration/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1440p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='1440p Series CGI List' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1440p Series CGI List' image='/images/Search/AU_SearchThumb_CGICommands_1440p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1440p_Serie_CGI_Befehle/' locationFR='/fr/1440p_Series_CGI_List/' crumbLabel="IP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-network-menu--ip-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-network-menu--ip-configuration",
        "aria-label": "the network menu  ip configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Network Menu :: IP Configuration`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-network-menu--ip-configuration"
        }}>{`The Network Menu :: IP Configuration`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetnetattr"
            }}>{`param.cgi?cmd=getnetattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-your-cameras-network-configuration"
                }}>{`Get your Camera´s Network Configuration`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgethttpattr"
            }}>{`param.cgi?cmd=gethttpattr`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#get-your-cameras-http-port"
            }}>{`Get your Camera´s HTTP Port`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-1"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgethttpsattr"
            }}>{`param.cgi?cmd=gethttpsattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-your-cameras-https-port"
                }}>{`Get your Camera´s HTTPS Port`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-2"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetrtspport"
            }}>{`param.cgi?cmd=getrtspport`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-your-cameras-rtsp-port"
                }}>{`Get your Camera´s RTSP Port`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-3"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetlanmac"
            }}>{`param.cgi?cmd=getlanmac`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-your-cameras-lan-mac"
                }}>{`Get your Camera´s LAN MAC`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-4"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetwifimac"
            }}>{`param.cgi?cmd=getwifimac`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-your-cameras-wlan-mac"
                }}>{`Get your Camera´s WLAN MAC`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-5"
                }}>{`Example`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <hr></hr>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetnetattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetnetattr",
        "aria-label": "paramcgicmdgetnetattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getnetattr`}</h2>
    <h3 {...{
      "id": "get-your-cameras-network-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-your-cameras-network-configuration",
        "aria-label": "get your cameras network configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s Network Configuration`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`dhcpflag`}</code>{`: on: (DHCP enabled), off: (DHCP disabled)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ip`}</code>{`: Current LAN IPv4 Address`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`netmask`}</code>{`: LAN Subnet Mask`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`gateway`}</code>{`: LAN Gateway`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`dnsstat`}</code>{`: DNS Status: 0 (manually), 1 (from DHCP Server)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`fdnsip`}</code>{`: Primary DNS`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`sdnsip`}</code>{`: Secondary DNS`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`macaddress`}</code>{`: LAN MAC Address`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`networktype`}</code>{`: LAN or WiFi - Indicates Type of current connection`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`wifi`}</code>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code>{`: (WLAN module enabled), `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`0`}</code>{`: (WLAN module disabled)`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getnetattr

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getnetattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`dhcpflag`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`dnsstat`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`fdnsip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`gateway`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.19"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`macaddress`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"EA:6D:C8:9C:DF:A7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`netmask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"255.255.255.0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`networktype`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"LAN"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`sdnsip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`wifi`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getnetattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getnetattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`dhcpflag`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`dnsstat`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`fdnsip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`gateway`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.19"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`macaddress`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"EA:6D:C8:9C:DF:A7"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`netmask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"255.255.255.0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`networktype`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"LAN"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`sdnsip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`wifi`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setnetattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`dhcpflag`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.24`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`netmask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`255.255`}</span>{`.255.0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`gateway`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`fdnsip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`sdnsip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8.8`}</span>{`.8.8

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setnetattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgethttpattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgethttpattr",
        "aria-label": "paramcgicmdgethttpattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=gethttpattr`}</h2>
    <h2 {...{
      "id": "get-your-cameras-http-port",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#get-your-cameras-http-port",
        "aria-label": "get your cameras http port permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s HTTP Port`}</h2>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`httpport`}</code>{`: Camera LAN Port`}</li>
    </ul>
    <p><strong parentName="p">{`Remark`}</strong>{`: Camera needs to be manually rebooted after setting the port`}</p>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gethttpattr
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gethttpattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`httpport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"80"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gethttpattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gethttpattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`httpport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"80"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`sethttpattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`httpport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"sethttpattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgethttpsattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgethttpsattr",
        "aria-label": "paramcgicmdgethttpsattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=gethttpsattr`}</h2>
    <h3 {...{
      "id": "get-your-cameras-https-port",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-your-cameras-https-port",
        "aria-label": "get your cameras https port permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s HTTPS Port`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`httpsport`}</code>{`: Camera Secured LAN Port`}</li>
    </ul>
    <p><strong parentName="p">{`Remark`}</strong>{`: Camera needs to be manually rebooted after setting the port`}</p>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gethttpsattr
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gethttpsattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`httpsport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"443"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gethttpsattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gethttpsattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`httpsport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"443"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`sethttpsattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`httpsport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"sethttpsattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetrtspport",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetrtspport",
        "aria-label": "paramcgicmdgetrtspport permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getrtspport`}</h2>
    <h3 {...{
      "id": "get-your-cameras-rtsp-port",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-your-cameras-rtsp-port",
        "aria-label": "get your cameras rtsp port permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s RTSP Port`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`rtspport`}</code>{`: Real Time Streaming Protocol Port`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`enable`}</code>{`: Deactivate the RTSP Video Service`}</li>
    </ul>
    <p><strong parentName="p">{`Remark`}</strong>{`: Camera needs to be manually rebooted after setting the port`}</p>
    <h3 {...{
      "id": "example-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-3",
        "aria-label": "example 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getrtspport

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getrtspport"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`rtspport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"554"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getrtspport`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getrtspport"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`rtspport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"554"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setrtspport`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`rtspport`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`554`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setrtspport"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetlanmac",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetlanmac",
        "aria-label": "paramcgicmdgetlanmac permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getlanmac`}</h2>
    <h3 {...{
      "id": "get-your-cameras-lan-mac",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-your-cameras-lan-mac",
        "aria-label": "get your cameras lan mac permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s LAN MAC`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`macaddress`}</code>{`: Camera LAN MAC address`}</li>
    </ul>
    <h3 {...{
      "id": "example-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-4",
        "aria-label": "example 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getlanmac

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getlanmac"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`macaddress`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7a:1b:d8:02:2d:21"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getlanmac`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getlanmac"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`macaddress`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"7a:1b:d8:02:2d:21"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetwifimac",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetwifimac",
        "aria-label": "paramcgicmdgetwifimac permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getwifimac`}</h2>
    <h3 {...{
      "id": "get-your-cameras-wlan-mac",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-your-cameras-wlan-mac",
        "aria-label": "get your cameras wlan mac permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s WLAN MAC`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`macaddress`}</code>{`: Camera WLAN MAC address`}</li>
    </ul>
    <h3 {...{
      "id": "example-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-5",
        "aria-label": "example 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getwifimac

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getwifimac"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`macaddress`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e0:09:bf:68:67:af"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getwifimac`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getwifimac"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`macaddress`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e0:09:bf:68:67:af"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      